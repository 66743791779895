import React, { useContext, useEffect, useState } from 'react';
import { ConversationContext } from '../../context/ConversationContext/ConversationContext';
import { TaskContext } from '../../context/TaskContext/TaskContext';
import './Conversation.css'; // Importer le fichier CSS pour les styles

const Conversation = ({ convId }) => {
    const {
        conversationId,
        setConversationId,
        loading,
        messages,
        newMessage,
        setNewMessage,
        getConversationById,
        sendMessage,
    } = useContext(ConversationContext);

    const { members } = useContext(TaskContext);
    const [isOpen, setIsOpen] = useState(false); // état pour ouvrir/fermer la conversation

    const handleSendMessage = async () => {
        if (!newMessage) return; // Assurer que le message n'est pas vide
        await sendMessage(convId, newMessage, members);
        setNewMessage(''); // Réinitialiser après l'envoi
    };

    const toggleConversation = () => {
        setIsOpen(prevState => !prevState);
    };

    const handleGetConv = async () => {
        //setIsLoading(true); // Définir que les messages sont en cours de chargement
        await getConversationById(convId);
        //setIsLoading(false); // Terminer le chargement
    };

    useEffect(() => {
        handleGetConv();
    }, [convId]);

    useEffect(() => {
        console.log("In conversation loading is : ", loading);
    }, [loading]);

    return (
        <div>
            {/* Boîte de discussion */}
            <div className={`conversation-box ${isOpen ? 'open' : ''}`}>
                <h2>Conversation</h2>
                <div className="messages">
                    {loading === true ? ( // Afficher un message ou un indicateur de chargement
                        <div>Loading messages...</div>
                    ) : (
                        messages.flat().map((message, index) => (
                            <div key={index} className="message">
                                <strong>{message.senderEmail}</strong>: {message.content}
                            </div>
                        ))
                    )}
                </div>
                {isOpen && (
                    <div className="input-container">
                        <input
                            type="text"
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            placeholder="Type a message"
                        />
                        <button onClick={handleSendMessage}>Send</button>
                    </div>
                )}
            </div>

            {/* Bouton pour ouvrir/fermer la conversation */}
            <button className="toggle-btn" onClick={toggleConversation}>
                {isOpen ? 'Close' : 'Open Chat'}
            </button>
        </div>
    );
};

export default Conversation;
