/*import React, { useContext, useEffect, useState } from 'react';
import { TaskContext } from '../../context/TaskContext/TaskContext';
import './TicketModal.css';

const TicketModal = ({ isOpen, onClose }) => {
    const { selectedTicket, updateTicket, ticketField } = useContext(TaskContext);
    const [updatedTicket, setUpdatedTicket] = useState(selectedTicket);

    const handleChange = (e) => {
        setUpdatedTicket({
            ...updatedTicket,
            [e.target.name]: e.target.value
        });
    };

    const handleClose = () => {
        onClose();
    };

    useEffect(() => {
        if (selectedTicket) {
            setUpdatedTicket(selectedTicket);
        }
    }, [selectedTicket]);

    const handleAccept = async () => {
        await updateTicket(updatedTicket.createDate, updatedTicket);
        handleClose();
    };

    const renderField = () => {
        const fieldMapping = {
            description: (
                <textarea
                    name="description"
                    value={updatedTicket.description}
                    onChange={handleChange}
                    className="ticket-modal-description"
                />
            ),
            title: (
                <input
                    type="text"
                    name="title"
                    value={updatedTicket.title}
                    onChange={handleChange}
                    className="ticket-modal-title"
                />
            ),
            dueDate: (
                <input
                    type="datetime-local"
                    name="dueDate"
                    value={updatedTicket.dueDate.split('.')[0]} // Assurez-vous que c'est au format approprié
                    onChange={handleChange}
                    className='ticket-modal-date'
                />
            )
        };

        return fieldMapping[ticketField] || null; // Utiliser ticketField à la place de selectedField
    };

    return isOpen ? (
        <div className="modal">
            <div className="modal-content">
                <h2>Edit {ticketField}</h2>
                {renderField()}
                <div className="modal-buttons">
                    <button className="cancel-button" onClick={handleClose}>✕ Annuler</button>
                    <button className="accept-button" onClick={handleAccept}>✓ Accepter</button>
                </div>
            </div>
        </div>
    ) : null;
};

export default TicketModal;*/
import React, { useContext, useEffect, useState } from 'react';
import { TaskContext } from '../../context/TaskContext/TaskContext';
import './TicketModal.css';

const TicketModal = ({ isOpen, onClose }) => {
    const { selectedTicket, updateTicket, ticketField } = useContext(TaskContext);
    const [updatedTicket, setUpdatedTicket] = useState(selectedTicket);

    const handleChange = (e) => {
        setUpdatedTicket({
            ...updatedTicket,
            [e.target.name]: e.target.value
        });
    };

    const handleClose = () => {
        onClose();
    };

    useEffect(() => {
        if (selectedTicket) {
            setUpdatedTicket(selectedTicket);
        }
    }, [selectedTicket]);

    const handleAccept = async () => {
        await updateTicket(updatedTicket.createDate, updatedTicket);
        handleClose();
    };

    const renderField = () => {
        const fieldMapping = {
            description: (
                <textarea
                    aria-label="modal-description"
                    name="description"
                    value={updatedTicket.description}
                    onChange={handleChange}
                    className="ticket-modal-description"
                />
            ),
            title: (
                <input
                    aria-label="modal-title"
                    type="text"
                    name="title"
                    value={updatedTicket.title}
                    onChange={handleChange}
                    className="ticket-modal-title"
                />
            ),
            dueDate: (
                <input
                    aria-label="modal-dueDate"
                    type="datetime-local"
                    name="dueDate"
                    value={updatedTicket.dueDate.split('.')[0]} // Ensure proper format
                    onChange={handleChange}
                    className='ticket-modal-date'
                />
            )
        };

        return fieldMapping[ticketField] || null; // Use ticketField to render the correct input
    };

    return isOpen ? (
        <div className="modal" role="dialog" aria-modal="true" aria-labelledby="modal-title">
            <div className="modal-content">
                <h2 id="modal-title">Edit {ticketField}</h2>
                {renderField()}
                <div className="modal-buttons">
                    <button aria-label="close-button" className="cancel-button" onClick={handleClose}>✕ Annuler</button>
                    <button aria-label="accept-button" className="accept-button" onClick={handleAccept}>✓ Accepter</button>
                </div>
            </div>
        </div>
    ) : null;
};

export default TicketModal;
