import React, { useState, memo } from 'react';
import AlertService from '../../utils/AlertService';

const ProjectCard = memo(({ projects, onDelete, onClick }) => {
    // Méthode pour marquer un projet comme complété
    const handleDelete = (project) => {
        if(project.owner === sessionStorage.getItem('mail')){
            onDelete(project.id);
        }
        else{
            AlertService.info('Seul le propriétaire du projet peut le supprimer.'); 
        }
    };

    return (
        <div className="project-list">
            {projects.map((project) => (
                 <div 
                        key={project.id} 
                        className="project-card" 
                        onClick={() => onClick(project.id)}
                    >
                    <button 
                        className="delete-button-project"
                        onClick={(e) => {
                            e.stopPropagation();
                            //onDelete(project.id);
                            handleDelete(project);
                        }}
                    >
                        ✕
                    </button>
                    <div className="project-info">
                        <h3>{project.name}</h3>
                        <p>Description: {project.description}</p>
                        <p>Start Date: {new Date(project.startDate).toLocaleDateString()}</p>
                        <p>End Date: {new Date(project.endDate).toLocaleDateString()}</p>
                        <p>Owner: {project.owner}</p>
                        <p>Members: {project.members}</p>
                    </div>
                </div>
            ))}
        </div>
    );
});

export default ProjectCard;
