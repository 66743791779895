import React, { useState, useContext } from 'react'; // Importation de React et des hooks useState et useContext pour gérer l'état local et accéder au contexte.
import { TaskContext } from '../../context/TaskContext/TaskContext'; // Importation du contexte des tâches pour utiliser addTicket.
import '@fortawesome/fontawesome-free/css/all.min.css'; // Importation des styles FontAwesome pour les icônes.
import AlertService from '../../utils/AlertService';

const FormTask = ({projectId, handleBackClick}) => { // Déclaration du composant fonctionnel FormTask avec des props pour projectId et une fonction handleBackClick.
    const { addTicket, projectBegin, projectEnd } = useContext(TaskContext); // Récupération de la fonction addTicket à partir du contexte TaskContext.
    
    // Déclaration des états pour gérer les champs du formulaire.
    const [projectName, setProjectName] = useState(""); // État pour le nom de la tâche.
    const [projectDate, setProjectDate] = useState(""); // État pour la date d'échéance de la tâche.
    const [description, setDescription] = useState(""); // État pour la description de la tâche.
    const [status, setStatus] = useState("To Do"); // État pour le statut de la tâche, initialisé à "To Do".

    // Fonction de gestion de la soumission du formulaire.
    const handleSubmit = async (event) => {
        event.preventDefault(); // Empêche le comportement par défaut du formulaire.

        // Création d'un nouvel objet ticket à partir des valeurs des champs.
        const newTicket = {
            title: projectName, // Titre de la tâche.
            description, // Description de la tâche.
            createDate: new Date().toISOString(), // Date de création au format ISO.
            dueDate: projectDate, // Date d'échéance.
            status, // Statut de la tâche.
            userMail: sessionStorage.getItem('mail'), // Récupère l'email de l'utilisateur depuis sessionStorage.
            projectId: projectId // ID du projet auquel la tâche est associée.
        };

        if (new Date(projectDate) <= projectBegin || new Date(projectDate) > projectEnd){
            AlertService.info("La date de fin de la tâche doit correspondre aux dates du projet.");
            return;
        }

        try{
            await addTicket(newTicket); // Appelle la fonction addTicket pour ajouter la tâche.
            AlertService.success('Tâche crée avec succès.');
            resetFormFields(); // Réinitialise les champs du formulaire après la soumission réussie.
        }
        catch(error){
            AlertService.error('Erreur de création de la tâche.');
            console.log('Erreur de création de la tâche : ', error);
        }
    };

    // Fonction pour réinitialiser les champs du formulaire.
    const resetFormFields = () => {
        setProjectName(""); // Réinitialise le champ du nom de la tâche.
        setDescription(""); // Réinitialise le champ de la description.
        setProjectDate(""); // Réinitialise le champ de la date d'échéance.
        setStatus("To Do"); // Réinitialise le statut à "To Do".
    };

    // Retourne le JSX à rendre.
    return (
        <div className="form-border"> {/* Conteneur pour le formulaire avec un style bordé */}
            <div className="wrap-utility center"> {/* Conteneur centré pour les éléments à l'intérieur */}
                <button className="back-button" onClick={() => handleBackClick()}> {/* Bouton pour revenir en arrière */}
                    <i className="fas fa-arrow-left"></i> {/* Icône de retour FontAwesome */}
                </button>
                <h1 className="h1-small">Add a new task to your list</h1> {/* Titre du formulaire */}
                <form onSubmit={handleSubmit} className="form_to-do"> {/* Formulaire avec gestion de la soumission */}
                    <input
                        className="input rm-10 w-input" // Classes pour le style
                        placeholder="Task Name" // Placeholder pour le champ
                        value={projectName} // Valeur liée à l'état
                        onChange={(e) => setProjectName(e.target.value)} // Met à jour l'état lorsque l'utilisateur tape
                        required // Champ requis
                    />
                    <input
                        className="input rm-10 w-input"
                        placeholder="Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)} // Met à jour l'état de la description
                        required
                    />
                    <input
                        className="input rm-10 w-input"
                        type="datetime-local" // Type pour une entrée de date et heure
                        placeholder="Due date"
                        value={projectDate}
                        onChange={(e) => setProjectDate(e.target.value)} // Met à jour l'état de la date d'échéance
                        required
                    />
                    <select
                        className="input-select rm-10 w-input" // Classe pour le style du select
                        value={status} // Valeur liée à l'état du statut
                        onChange={(e) => setStatus(e.target.value)} // Met à jour l'état lorsque l'utilisateur change le statut
                        required
                    >
                        <option label="todo" value="To Do">To Do</option> // Options du select pour le statut
                        <option label="progress" value="In Progress">In Progress</option>
                        <option label="testing" value="In Testing">In Testing</option>
                        <option label="done" value="Done">Done</option>
                    </select>
                    <button type="submit" className="button w-button"> {/* Bouton de soumission */}
                        Save item
                    </button>
                </form>
            </div>
        </div>
    );
};

export default FormTask; // Exportation du composant pour l'utiliser ailleurs dans l'application.
