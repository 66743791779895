import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useMatch } from 'react-router-dom';
import { logout, getAllUsers } from '../../service/AuthService';
import './Navbar.css';
import { TaskContext } from '../../context/TaskContext/TaskContext';
import { ProjectContext } from '../../context/ProjectContext/ProjectProvider';

const Navbar = () => {
  const navigate = useNavigate();
  const match = useMatch('/task/:projectId');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false); // pour le menu des utilisateurs
  const menuRef = useRef(null);
  const userMenuRef = useRef(null); // Référence pour le menu des utilisateurs
  const [users, setUsers] = useState([]); // État pour les utilisateurs
  const { resetTasks, handleAddMember, members, owner, handleDeleteMember } = useContext(TaskContext);
  const { resetProjects } = useContext(ProjectContext);
  const userEmail = sessionStorage.getItem('mail');

  const handleLogout = () => {
    logout();
    resetTasks();
    resetProjects();
    navigate('/');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleUserMenu = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
    if (!isUserMenuOpen) {
      fetchUsers();
    }
  };

  const fetchUsers = async () => {
    try {
      const userData = await getAllUsers(); 
      console.log(userData);

      const filteredUsers = userData.filter(user => 
        user.username !== userEmail && 
        user.username && 
        !members.includes("\"" + user.username + "\"") 
      );
      setUsers(filteredUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  
  const handleUserSelect = (user) => {
    console.log(user.username);
    handleAddMember(user.username);
    setIsUserMenuOpen(false);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
    if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
      setIsUserMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDelete = (email) => {
    if (userEmail === owner){
      handleDeleteMember(email);
      console.log(email, " supprimé");
    }
  }

  return (
    <nav className='navbar'>
      {/* Conteneur pour le bouton de déconnexion */}
      <div className='logoutContainer'>
        <button onClick={toggleMenu} className='logoutButton'>
          {userEmail}
        </button>
        {isMenuOpen && (
          <div ref={menuRef} className='logDropdown'>
            <button onClick={() => navigate('/settings')} className='menuItem'>
              Settings
            </button>
            <button onClick={handleLogout} className='menuItem'>
              Log out
            </button>
          </div>
        )}
      </div>

      {/* Afficher la section utilisateur uniquement si l'utilisateur est sur la page '/tasks' */}
      {match && ( 
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {members.map((user, index) => (
            user !== userEmail && (
              <button key={index} className='userButton' onClick={() => handleDelete(user)}>
                {user}
              </button>
            )
          ))}

          {/* Afficher le propriétaire */}
          {owner && owner !== userEmail && (
            <button className='userButton'>
              {owner}
            </button>
          )}
          <button onClick={toggleUserMenu} className='addUserButton'>+</button>
          {isUserMenuOpen && (
            <div ref={userMenuRef} className='userDropdown'>
              {users
                .filter(user => !members.includes(user.username) && user.username !== owner)
                .map(user => (
                  <button key={user.id} onClick={() => handleUserSelect(user)} className='menuItem'>
                    {user.username}
                  </button>
                ))}
            </div>
          )}
        </div>
      )}
    </nav>
  );
};

export default Navbar;
