import React, { useEffect, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { TaskContext } from '../../context/TaskContext/TaskContext';
import './ProjectScreen.css';
import Navbar from '../../component/Navbar/Navbar';
import { ProjectContext } from '../../context/ProjectContext/ProjectProvider';
import ProjectCard from '../../component/ProjectCard/ProjectCard';
import FormProject from '../../component/FormProject/FormProject';

const ProjectScreen = () => {
    const { projectsOwned, getProjectsOwned, projectsMember, fetchProjectsByMember, loadingOwned, loadingMember, deleteProject } = useContext(ProjectContext);
    const { setProjectId } = useContext(TaskContext);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProjects = async () => {
            await Promise.all([getProjectsOwned(), fetchProjectsByMember()]);
        };
        fetchProjects();
    }, []);

    const ownedProjects = useMemo(() => projectsOwned, [projectsOwned]);
    const memberProjects = useMemo(() => projectsMember, [projectsMember]);

    useEffect(() => {
        console.log("Projects Member:", projectsMember);
        console.log("Projects Owned:", projectsOwned);
    }, [projectsMember, projectsOwned]);

    const handleProjectClick = (projectId) => {
        setProjectId(projectId);
        navigate(`/task/${projectId}`);
    };

    return (
        <div className='project-screen'>
            <Navbar/>
            <FormProject/>

            {/* Section des projets détenus */}
            <div className="project-section">
                <div className="project-header">
                    <h3>Owned Projects</h3>
                </div>
                {loadingOwned ? (
                    <h2>Loading owned projects...</h2>
                ) : ownedProjects.length === 0 ? (
                    <h2>You don't have owned projects.</h2>
                ) : (
                    <ProjectCard 
                        title="Owned Projects" 
                        projects={ownedProjects} 
                        onDelete={deleteProject} 
                        onClick={handleProjectClick} 
                    />
                )}
            </div>

            {/* Section des projets en tant que membre */}
            <div className="project-section">
                <div className="project-header">
                    <h3>Member Projects</h3>
                </div>
                {loadingMember ? (
                    <h2>Loading member projects...</h2>
                ) : memberProjects.length === 0 ? (
                    <h2>You don't have member projects.</h2>
                ) : (
                    <ProjectCard 
                        title="Member Projects" 
                        projects={memberProjects} 
                        onDelete={deleteProject} 
                        onClick={handleProjectClick} 
                    />
                )}
            </div>
        </div>
    );
};

export default ProjectScreen;
