import React from 'react';
import './SignupModal.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Swal from 'sweetalert2';
import { signup } from '../../service/AuthService';
import AlertService from '../../utils/AlertService';

const SignupModal = ({ backClick }) => {

  const handleBackClick = () => {
    backClick();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.target);

    if (data.get("Password") !== data.get("Password2")) {
      AlertService.error('Les mots de passes doivent être identiques.');
      return;
    };

    const user = {
      username: data.get("Email"),
      password: data.get("Password"),
      role: "USER"
    };

    try {

      const response = await signup(user);
      AlertService.success('Inscription réussie !').then((result) => {
        if (result.isConfirmed) {
          console.log('Utilisateur inscrit:', response);
          backClick();
        }
      });
    } catch (error) {
      // Gestion de l'erreur et affichage d'une alerte d'erreur
      AlertService.error('Échec de la création du compte. Veuillez réessayer.');
    }
  }

  return (
    <div className='form-border w-form'>
      <div className='back-button-wrapper'>
        <button aria-label="back" className="back-button" onClick={() => handleBackClick()}>
          <i className="fas fa-arrow-left"></i>
        </button>
      </div>
      <form id='wf-form-signup' className='wf-form-signup' name='wf-form-signup' data-name='signup' onSubmit={handleSubmit}>
        <h1 className='h1-small center'>Sign up</h1>
        <div className='input-wrapper'>
          <input className='input rm-10 w-input' maxLength="256" name="Email" data-name="Email" aria-label="email" placeholder='Enter your email address ...' type='email' id='Email' required></input>
        </div>
        <div className='input-wrapper'>
          <input className='input rm-10 w-input' maxLength="256" name="Password" data-name="Password" aria-label="password" placeholder='****************' type='password' id='Password' required></input>
        </div>
        <div className='input-wrapper'>
          <input className='input rm-10 w-input' maxLength="256" name="Password2" data-name="Password2" aria-label="password" placeholder='****************' type='password' id='Password2' required></input>
        </div>
        <input aria-label="submit-button" type="submit" data-wait="Please wait..." className='button in-form w-button' value="Sign up"></input>
        <div className='sign-up_buttons_100w'></div>
      </form>
    </div>
  );
};

export default SignupModal;