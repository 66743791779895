import React, { useContext, useEffect } from 'react';
import './TaskScreen.css';
import { useParams, useNavigate } from 'react-router-dom';
import { TaskContext } from '../../context/TaskContext/TaskContext';
import { ConversationContext } from '../../context/ConversationContext/ConversationContext';
import TicketBoard from '../../component/TicketBoard/TicketBoard';
import Navbar from '../../component/Navbar/Navbar';
import '@fortawesome/fontawesome-free/css/all.min.css';
import FormTask from '../../component/FormTask/FormTask';
import Conversation from '../../component/Conversation/Conversation';

const TaskScreen = () => {
    const { resetTasks } = useContext(TaskContext);
    const { resetConversation, setConversationId, getConversationById } = useContext(ConversationContext);
    const { projectId } = useParams();
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate('/project');
        resetTasks(); 
        resetConversation();
    };

    useEffect(() => {
        const fetchConv = async () => {
            console.log("TaskScreen get id : ", projectId)
            const conv = await getConversationById(projectId);
            setConversationId(projectId);
            console.log("TaskScreen set conversation id with : ", conv)
        };
        fetchConv();
    },[])

    return (
        <div className="info-screen">
            <Navbar />
            <div className="w-layout-grid grid">
                <FormTask projectId={projectId} handleBackClick={handleBackClick}/>
                <TicketBoard projectId={projectId}/>
            </div>
            <Conversation convId={projectId} />
        </div>
    );
};

export default TaskScreen;
