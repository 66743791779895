import React from 'react';
import './LoginModal.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import AlertService from '../../utils/AlertService';
import { login } from '../../service/AuthService';

const LoginModal = ({ backClick, goToProject }) => {

  const handleBackClick = () => {
    backClick();
  };

  const handleProjectClick = () => {
    goToProject();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.target);

    const user = {
      username: data.get("Email"), 
      password: data.get("Password"),
      role: "USER"
    };

    try {
      const token = await login(user);
      console.log(token);
      AlertService.success('Connexion réussie !').then(() => {
        handleProjectClick();
      });

    } catch (error) {
      AlertService.error('Identifiants invalides, veuillez réessayer.');
    }
  }

  return (
    <div className='form-border w-form'>
      <div className='back-button-wrapper'>
        <button aria-label="back" className="back-button" onClick={() => handleBackClick()}>
          <i className="fas fa-arrow-left"></i>
        </button>
      </div>
      <form id='wf-form-login' className='wf-form-login' name='wf-form-login' data-name='login' onSubmit={handleSubmit}>
        <h1 className='h1-small center'>Login</h1>
        <div className='input-wrapper'>
          <input className='input rm-10 w-input' maxLength="256" name="Email" data-name="Email" aria-label="email" placeholder='Enter your email address ...' type='email' id='Email' required></input>
        </div>
        <div className='input-wrapper'>
          <input className='input rm-10 w-input' maxLength="256" name="Password" data-name="Password" aria-label="password" placeholder='****************' type='password' id='Password' required></input>
        </div>
        <div className="form-row flex-end">
          <div className="position-relative">
            <a data-ms-modal="forgot-password" className='link'>Forgot password ?</a>
          </div>
        </div>
        <input aria-label="submit-button" type="submit" className='button in-form w-button' value="Log in"></input>
        <div className='log-in_buttons_100w'></div>
      </form>
    </div>
  );
};

export default LoginModal;