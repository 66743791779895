import React, { useState, useContext } from 'react'; // Importation de React et des hooks useState et useContext.
import { ProjectContext } from '../../context/ProjectContext/ProjectProvider'; // Importation du contexte pour gérer les projets.
import AlertService from '../../utils/AlertService';

const FormProject = () => { // Déclaration du composant fonctionnel FormProject.
    // Récupération de la fonction addProject à partir du contexte ProjectContext.
    const { addProject } = useContext(ProjectContext); 

    // Déclaration des états pour les différents champs du formulaire.
    const [name, setName] = useState(""); // État pour le nom du projet.
    const [description, setDescription] = useState(""); // État pour la description du projet.
    const [beginDate, setBeginDate] = useState(""); // État pour la date de début.
    const [endDate, setEndDate] = useState(""); // État pour la date de fin.

    // Fonction de gestion de la soumission du formulaire.
    const handleSubmit = async (event) => {
        event.preventDefault(); // Empêche le comportement par défaut de l'envoi du formulaire.

        // Création d'un nouvel objet projet à partir des valeurs des champs.
        const newProject = {
            name: name,
            description: description,
            owner: sessionStorage.getItem('mail'), // Récupère l'email de l'utilisateur à partir du sessionStorage.
            startDate: beginDate,
            endDate: endDate,
        };

        if (new Date(beginDate) >= new Date(endDate)) {
            AlertService.info('La date de début doit être inférieur à la date de fin.');
            return; // Empêcher la soumission du formulaire si la condition n'est pas respectée
        }

        if(new Date(beginDate) < new Date() || new Date(endDate) < new Date()){
            AlertService.info("Les dates ne peuvent être inférieur à la date d'aujourd'hui");
            return;
        }

        try {
            await addProject(newProject); // Appelle la fonction addProject pour ajouter le projet.
            AlertService.success('Projet crée avec succès.');
            resetFormFields(); // Réinitialise les champs du formulaire après soumission réussie.
        } catch (error) {
            AlertService.error('Erreur lors de la création du projet.');
            console.error('Erreur lors de la création du projet:', error); // Gère les erreurs en cas d'échec.
        }
    };

    // Fonction pour réinitialiser les champs du formulaire.
    const resetFormFields = () => {
        setName(""); // Réinitialise le champ du nom.
        setDescription(""); // Réinitialise le champ de la description.
        setBeginDate(""); // Réinitialise le champ de la date de début.
        setEndDate(""); // Réinitialise le champ de la date de fin.
    };

    // Retourne le JSX à rendre
    return (
        <div className="w-layout-grid grid"> {/* Conteneur pour la grille */}
            <div className="form-border"> {/* Conteneur pour le formulaire avec un style bordé */}
                <div className="wrap-utility center"> {/* Conteneur centré pour le titre et le formulaire */}
                    <h1 className="h1-small">Add a new Project to your list</h1> {/* Titre du formulaire */}
                    <form onSubmit={handleSubmit} className="form_project"> {/* Formulaire avec gestion de soumission */}
                        <input
                            className="input rm-10 w-input" // Classes pour le style
                            placeholder="Project Name" // Placeholder pour le champ
                            value={name} // Valeur liée à l'état
                            onChange={(e) => setName(e.target.value)} // Met à jour l'état lorsque l'utilisateur tape
                            required // Champ requis
                        />
                        <input
                            className="input rm-10 w-input"
                            placeholder="Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                        />
                        <input
                            className="input rm-10 w-input"
                            type="datetime-local" // Type pour une entrée de date et heure
                            placeholder="Begin date"
                            value={beginDate}
                            onChange={(e) => setBeginDate(e.target.value)}
                            required
                        />
                        <input
                            className="input rm-10 w-input"
                            type="datetime-local"
                            placeholder="End date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            required
                        />
                        <button aria-label="submit-button" type="submit" className="button w-button"> {/* Bouton de soumission */}
                            Add Project
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default FormProject; 
