import React, { useContext, useState } from 'react';
import { TaskContext } from '../../context/TaskContext/TaskContext';
import TicketModal from '../TicketModal/TicketModal';
import AlertService from '../../utils/AlertService';

const TicketBoard = () => {
    const { tickets, updateTicket, deleteTicket, selectedTicket, selectTicket, clearSelectedTicket, selectTicketField, clearTicketField } = useContext(TaskContext);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleDragStart = (event, ticket) => {
        event.dataTransfer.setData('ticket', JSON.stringify(ticket));
    };

    const handleDrop = (event, newStatus) => {
        event.preventDefault();
        const ticketData = JSON.parse(event.dataTransfer.getData('ticket'));
        updateTicket(ticketData.createDate, { ...ticketData, status: newStatus });
    };

    const handleDelete = async (createDate) => {
        try {
            await deleteTicket(createDate);
            AlertService.success('Tâche supprimée avec succès !');
            console.log("Tâche supprimée avec succès !");
        } catch (error) {
            AlertService.error("Erreur lors de la suppression de la tâche.");
            console.error("Erreur lors de la suppression de la tâche:", error);
        }
    };

    const openModal = (ticket, field) => {
        selectTicket(ticket); // Sélectionne le ticket
        selectTicketField(field);
        setIsModalOpen(true);  // Ouvre le modal
    };

    const closeModal = () => {
        clearSelectedTicket();
        clearTicketField();
        setIsModalOpen(false);
    };

    return (
        <div className="grid-phase">
            {["To Do", "In Progress", "In Testing", "Done"].map(status => (
                <div className="To_Do-row" key={status} onDrop={(event) => handleDrop(event, status)} onDragOver={(event) => event.preventDefault()}>
                    <div className="form-border-phase">
                        <div className="wrap-utility center">
                            <h1 className="h1-small">{status}</h1>
                        </div>
                    </div>
                    <div className="ticket-list">
                        {tickets.filter(ticket => ticket.status === status).map((ticket) => (
                            <div
                                className="ticket-item"
                                key={ticket.createDate}
                                draggable
                                onDragStart={(event) => handleDragStart(event, ticket)}
                            >
                                <div className="ticket-header">
                                    <strong className="ticket-title" onClick={() => openModal(ticket, "title")}>
                                        {ticket.title}
                                    </strong>
                                    <button 
                                        className="delete-button"
                                        onClick={(e) => {
                                            e.stopPropagation(); // Empêche le clic de se propager au parent
                                            handleDelete(ticket.createDate);
                                        }}
                                    >
                                        ✕
                                    </button>
                                </div>
                                <div className='ticket-date'>
                                    <span className="ticket-create-date">
                                        {new Date(ticket.createDate).toLocaleDateString('fr-FR', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric'
                                        })}{' '}
                                        {new Date(ticket.createDate).toLocaleTimeString('fr-FR', {
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        })}
                                    </span>
                                    <span className="ticket-due-date" onClick={() => openModal(ticket, "dueDate")}>
                                        {new Date(ticket.dueDate).toLocaleDateString('fr-FR', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric'
                                        })}{' '}
                                        {new Date(ticket.dueDate).toLocaleTimeString('fr-FR', {
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        })}
                                    </span>
                                </div>
                                <div className="ticket-description" onClick={() => openModal(ticket, "description")}>
                                    {ticket.description}
                                </div>
                                <div className="ticket-footer">
                                    <span className="ticket-status">{ticket.status}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
           {selectedTicket && (
                <TicketModal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                />
            )}
        </div>
    );
};

// Fonction utilitaire pour gérer les couleurs de statut
const getStatusColor = (status) => {
    switch (status) {
        case "To Do":
            return "red";
        case "In Progress":
            return "yellow";
        case "In Testing":
            return "orange";
        case "Done":
            return "green";
        default:
            return "black";
    }
};

export default TicketBoard;

