import React from 'react'; // Importation de la bibliothèque React pour pouvoir utiliser les composants React.

const DefaultModal = ({ loginClick, signupClick }) => { // Déclaration du composant fonctionnel DefaultModal, qui reçoit deux props : loginClick et signupClick.

    // Fonction pour gérer le clic sur le bouton de connexion.
    const handleLoginClick = () => {
        loginClick(); // Appelle la fonction loginClick passée en props pour gérer la logique de connexion.
    };

    // Fonction pour gérer le clic sur le bouton d'inscription.
    const handleSignupClick = () => {
        signupClick(); // Appelle la fonction signupClick passée en props pour gérer la logique d'inscription.
    };

    return (
        <>
            <h1 className='auth-title'>Welcome to Tasks list app</h1>
            <div className="form-border _650"> 
                <div className="wrap-utility center"> {/* Un conteneur centré pour les boutons */}
                    <div className="_2-button-wrap"> {/* Conteneur pour regrouper les boutons */}
                        <button aria-label="login-access" className="button w-button" onClick={() => handleLoginClick()}>Login</button> {/* Bouton de connexion */}
                        <button aria-label="signup-access" className="button w-button" onClick={() => handleSignupClick()}>Signup</button> {/* Bouton d'inscription */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default DefaultModal;
