// src/utils/AlertService.js
import Swal from 'sweetalert2';

class AlertService {
  
  static success(message, title = 'Succès') {
    return Swal.fire({
      title: title,
      text: message,
      icon: 'success',
      customClass: {
        popup: 'custom-alert-success',
        confirmButton: 'custom-button-success',
      },
      confirmButtonText: 'Okay',
    });
  }

  static error(message, title = 'Erreur') {
    return Swal.fire({
      title: title,
      text: message,
      icon: 'error',
      customClass: {
        popup: 'custom-alert-error',
        confirmButton: 'custom-button-error',
      },
      confirmButtonText: 'D’accord',
    });
  }

  static warning(message, title = 'Avertissement') {
    return Swal.fire({
      title: title,
      text: message,
      icon: 'warning',
      customClass: {
        popup: 'custom-alert-warning',
        confirmButton: 'custom-button-warning',
      },
      confirmButtonText: 'Okay',
    });
  }

  static info(message, title = 'Info') {
    return Swal.fire({
      title: title,
      text: message,
      icon: 'info',
      customClass: {
        popup: 'custom-alert-info',
        confirmButton: 'custom-button-info',
      },
      confirmButtonText: 'Okay',
    });
  }

  // Méthode pour une confirmation avec des boutons personnalisés
  static confirm(message, title = 'Confirmation') {
    return Swal.fire({
      title: title,
      text: message,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      customClass: {
        popup: 'custom-alert-confirm',
        confirmButton: 'custom-button-confirm',
        cancelButton: 'custom-button-cancel',
      },
    });
  }
}

export default AlertService;
